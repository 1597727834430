import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import backend from 'i18next-http-backend';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { createGlobalStyle } from 'styled-components';

import {
  globalStyles,
  SkipToContent,
  Footer,
  ToastProvider,
  GlobalLoadingPage,
  PageWidth, BREAKPOINTS,
  COLORS
} from "@laerdal/life-react-components";

import NotFoundPage from './components/NotFoundPage';
import Header from './components/header/Header';

import AppErrorHandler from './AppErrorHandler';
import UnauthenticatedHandler from './UnauthenticatedHandler';
import OverviewPage from './features/overview/OverviewPage';
import CreateOrganizationPage from './features/organization/CreateOrganizationPage';
import OrganizationOnboard from './features/organization/OrganizationOnboard';
import OrganizationList from './features/organization/OrganizationList';
import UserList from './features/user/UserList';
import ServicesPage from './features/service/ServicesPage';
import ServicePage from './features/service/ServicePage';
import OrganizationPage from './features/organization/OrganizationPage';
import UnauthorizedPage from './UnauthorizedPage';
import UserPage from './features/user/UserPage';
import { UserContext } from './userContext';
import {AccessLevel, CountryConfiguration, CountryDto, Permission, SalesOrgDto} from './types';

// App insight related imports
import { reactPlugin } from './AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import OrganizationMergePage from './features/organization/OrganizationMergePage';
import ForbiddenPage from './ForbiddenPage';
import Api from './utils/api';
import AddNewToSVersion from './features/service/pages/AddNewToSVersion';
import { useLocation } from 'react-router';
import UserOnboard from './features/user/UserOnboard';
import OrganizationInfo from './features/organization/OrganizationInfo';
import UserInfo from './features/user/UserInfo';

const GlobalStyle = createGlobalStyle`${globalStyles}
/* To prevent things from jumping when scrollbar is present */
@media screen and (min-width: 960px) {
  html {
    overflow-y: scroll;
  }
}

#root {
  height: auto;
  min-height: 100%;
}

${PageWidth} {
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${BREAKPOINTS.MEDIUM} {
    gap: 12px;
  }

  ${BREAKPOINTS.LARGE} {
    gap: 16px;
  }
}
`;

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

const App = () => {
  const { t } = useTranslation();
  const [authenticated, setAuthenticated] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [accessLevel, setAccessLevel] = useState<AccessLevel>(AccessLevel.ReadOnly);
  const [onboarder, setOnboarder] = useState<boolean>(false);
  const [isAccountAndCustomerViewer, setIsAccountAndCustomerViewer] = useState<boolean>(false);
  const [countries, setCountries] = useState<CountryDto[]>([]);
  const [salesOrgs, setSalesOrgs] = useState<SalesOrgDto[]>([]);
  const [memberPermissions, setMemberPermissions] = useState<Permission[]>([]);
  const [roles, setRoles] = useState<string[]>([]);

  //just keep blank for now, will be used will checkout release
  const [selfServiceServices, setSelfServiceServices] = useState<string[]>([]);
  //just keep blank for now, will be used will checkout release
  const [countryConfigurations, setCountryConfigurations] = useState<{[key: string]: CountryConfiguration}>({});

  const location = useLocation();
  const navigate = useNavigate();

  // Redirect to forbidden page if user is not authorized
  Api.addResponseInterceptor(
    (response) => response,
    (error) => {
      if (error?.response?.status === 403) {
        navigate('/forbidden');
      }
      return Promise.reject(error);
    },
  );

  return (
    <AppInsightsErrorBoundary onError={() => <AppErrorHandler />} appInsights={reactPlugin}>
      <UserContext.Provider
        value={{
          authenticated,
          firstName,
          lastName,
          email,
          accessLevel,
          onboarder,
          isAccountAndCustomerViewer,
          countries,
          memberPermissions,
          setCountries,
          setAuthenticated,
          setFirstName,
          setLastName,
          setEmail,
          setAccessLevel,
          setOnboarder,
          setIsAccountAndCustomerViewer,
          salesOrgs,
          setSalesOrgs,
          setMemberPermissions,
          roles,
          setRoles,
          selfServiceServices,
          setSelfServiceServices,
          countryConfigurations,
          setCountryConfigurations,
        }}>
        <GlobalStyle />
        <SkipToContent>{t('Skip to main content')}</SkipToContent>
        <Helmet defaultTitle="Laerdal Cloud Control" titleTemplate="%s - Laerdal Cloud Control">
          <link rel="preconnect" href="https://cdn1.laerdal.com" />
          <link href="https://cdn1.laerdal.com/webfonts/fonts.css" rel="stylesheet" />
        </Helmet>
        <Header />
        <AppErrorHandler>
          <ToastProvider>
            <UnauthenticatedHandler>
              <Routes>
                <Route index element={<OverviewPage />} />
                <Route path="organization">
                  <Route index element={<OrganizationList />} />
                  <Route path="create" element={<CreateOrganizationPage />} />
                  <Route path="onboard" element={<OrganizationOnboard />} />
                  <Route path="info" element={<OrganizationInfo />} />
                  <Route path="merge" element={<OrganizationMergePage />} />
                  <Route path=":id/*" element={<OrganizationPage />} />
                </Route>
                <Route path="user">
                  <Route index element={<UserList navigate={navigate} location={location} />} />
                  <Route path="onboard" element={<UserOnboard />} />
                  <Route path="info" element={<UserInfo />} />
                  <Route path=":id/*" element={<UserPage />} />
                </Route>
                <Route path="service">
                  <Route index element={<ServicesPage />} />
                  <Route path=":serviceId/tos" >
                    <Route path=":tosDocId" element={<AddNewToSVersion />} />
                    <Route path="" element={<AddNewToSVersion />} />
                  </Route>
                  <Route path=":id/*" element={<ServicePage />} />
                </Route>
                <Route path="unauthorized" element={<UnauthorizedPage />} />
                <Route path="forbidden" element={<ForbiddenPage />} />
                <Route path="logout/*" element={<GlobalLoadingPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </UnauthenticatedHandler>
          </ToastProvider>
        </AppErrorHandler>
        <Footer />
      </UserContext.Provider>
    </AppInsightsErrorBoundary>
  );
};

export default App;
